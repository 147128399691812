<template>
  <div>
    <v-container grid-list-xs fluid>
      <v-layout row wrap>
        <v-flex xs12 sm12 md3 lg3 pa-2 v-for="item in items" :key="item">
          <v-card height="100%" flat>
            <v-img
              :src="item"
              height="100%"
              width="100%"
              lazy-src="https://picsum.photos/id/11/10/6"
            ></v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        require("../../assets/gallery/g1.jpeg"),
        require("../../assets/gallery/g2.jpg"),
        require("../../assets/gallery/g3.jpg"),
        require("../../assets/gallery/g4.jpg"),
        require("../../assets/gallery/g5.jpg"),
        require("../../assets/gallery/g6.jpg"),
        require("../../assets/gallery/g7.jpg"),
        require("../../assets/gallery/g8.jpg"),
      ],
    };
  },
};
</script>